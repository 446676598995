import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../Styles/Pagination.css'

const Pagination = ({ pagesCount, currentPage, switchLoader }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [buttons, setButtons] = useState([]);

    useEffect(() => {
        const generateButtons = () => {
            const btns = [];
      
            if (pagesCount <= 1) return btns;
      
            // Show first page
            btns.push(1);
      
            if (pagesCount <= 5) {
              // Show all pages if total pages is 5 or less
              for (let i = 2; i <= pagesCount; i++) {
                btns.push(i);
              }
            } else if (currentPage <= 3) {
              // Show first 4 pages and last page
              for (let i = 2; i <= 4; i++) {
                btns.push(i);
              }
              btns.push('...');
              btns.push(pagesCount);
            } else if (currentPage >= pagesCount - 2) {
              // Show first page, ellipsis and last 4 pages
              btns.push('...');
              for (let i = pagesCount - 3; i < pagesCount; i++) {
                btns.push(i);
              }
              btns.push(pagesCount);
            } else {
              // Show first page, ellipsis, current page +/- 1, and last page
              btns.push('...');
              btns.push(currentPage - 1);
              btns.push(currentPage);
              btns.push(currentPage + 1);
              btns.push('...');
              btns.push(pagesCount);
            }
      
            return btns;
          };
      

        setButtons(generateButtons());
    }, [pagesCount, currentPage]);

    const handlePageClick = (page) => {
        if (page === '...') return;

        switchLoader()
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', page);
        navigate(`/obituario?${searchParams.toString()}`);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            switchLoader()
            handlePageClick(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < pagesCount) {
            switchLoader()
            handlePageClick(currentPage + 1);
        }
    };

  return (
    <div className="pagination">
        <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className='pageButton arrows'
        >&lt;</button>

        {buttons.map((btn, index) =>
            btn === '...' ? (
            <span key={index} className="ellipsis">
                {btn}
            </span>
        ) : (
            <button
                key={index}
                onClick={() => handlePageClick(btn)}
                disabled={btn === currentPage}
                style={{ borderColor: btn === currentPage ? 'black' : '' }}
                className='pageButton'
            >{btn}</button>
        ))}

        <button
        onClick={handleNextPage}
        disabled={currentPage === pagesCount}
        className='pageButton arrows'
        >&gt;</button>
    </div>
  )
}

export default Pagination;
