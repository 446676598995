import axios from 'axios'
import env from './env'

const baseUrl = env === 'production' ? 'https://obituario.lutocuritiba.com.br/' : 'http://localhost:8080/'

export const api = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true
})